<template>
  <div class="container-fluid py-4 px-5">
    <!-- Modal -->
    <ModalOK :text="getMensagem" />
    <!-- Fim modal -->
    <div class="title-page">
      <span><img class="icone-title" src="../assets/img/icons/icon-alert.png" /></span>
      <p class="txt-title-page">DISPARAR ALERTA(S)</p>
    </div>

    <div class="row">
      <div class="col-lg-6 col-opcoes">
        <div class="px-0 py-0">
          <card-opcoes-alertas @muda-tipo="setChangeTipo" @muda-previsao="setChangePrevisao"
            @muda-severidade="setChangeSeveridade" @muda-via="setChangeVia" />
        </div>
        <div id="group-btn-dispara-alerta" class="position-relative bottom-0 w-100 d-flex align-content-end my-5">
          <btn-group @click-accept="disparaAlerta" />
        </div>
      </div>
      <div class="col-lg-6 cols-cards">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <card-preview-s-m-s :tipo="tipo ? tipo : getTipo[0].opcao"
              :previsao="previsao ? previsao : getPrevisao[0].opcao" :via="getOldViaValue" :sentido="getSentido"
              :trecho1="getTrecho.trecho1" :trecho2="getTrecho.trecho2" />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 ">
            <card-preview-w-p-p :tipo="tipo ? tipo : getTipo[0].opcao"
              :previsao="previsao ? previsao : getPrevisao[0].opcao"
              :severidade="nivel ? nivel.caminhoIMG : getNiveis[0].caminhoIMG" :via="getviaValue" :sentido="getSentido"
              :trecho1="getTrecho.trecho1" :trecho2="getTrecho.trecho2" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardOpcoesAlertas from "../components/cards/CardOpcoesAlertas.vue";
import CardPreviewSMS from "@/components/cards/CardPreviewSMS.vue";
import CardPreviewWPP from "@/components/cards/CardPreviewWPP.vue";
import BtnGroup from "@/components/btn/BtnGroup.vue";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import Alerta from "@/models/Alerta.js";
import { mapGetters, mapActions } from "vuex";
import ModalOK from "@/components/modal/modalOK.vue";


export default {
  components: { CardOpcoesAlertas, CardPreviewSMS, CardPreviewWPP, BtnGroup, ModalOK },
  name: "DisparoAlertas",
  data() {
    return {
      tipo: '',
      previsao: '',
      nivel: '',
      via: '',
    }
  },
  computed: {
    ...mapGetters([
      "getNiveis",
      "getTipo",
      "getPrevisao",
      "getVias",
      "getMensagem",
      "getTrecho",
      "getInformaTrecho",
      "getviaValue",
      "getOldViaValue",
      "getSentido"
    ]),
  },

  methods: {
    ...mapActions(["sendAlertas"]),

    setChangeTipo(selecionado) {
      this.tipo = selecionado;
    },
    setChangePrevisao(selecionado) {
      this.previsao = selecionado;
    },
    setChangeSeveridade(selecionado) {
      this.nivel = this.getNiveis.find(({ opcao }) => opcao === selecionado);
      return this.nivel;
    },
    setChangeVia(selecionado) {
      this.via = selecionado;
    },
    async disparaAlerta() {
      console.log("criando alerta");
      if (this.tipo === '') {
        this.tipo = this.getTipo[0].opcao;
      }
      if (this.tipo === 'Via Liberada') {
        this.previsao = ''
      }
      if (this.previsao === '') {
        this.previsao = this.getPrevisao[0].opcao;
      }
      if (this.nivel === '') {
        this.nivel = this.getNiveis[0];
      }
      if (this.via === '') {
        this.via = this.getVias[0].opcao;
      }

      let msgAlerta = new Alerta(this.tipo, this.previsao, this.nivel.opcao, this.$store.state.moduloDisparaAlertas.viaValue, this.$store.state.moduloDisparaAlertas.sentido, this.$store.state.moduloDisparaAlertas.trecho.trecho1, this.$store.state.moduloDisparaAlertas.trecho.trecho2);

      if (this.getInformaTrecho) {
        if (!this.getTrecho.trecho1.trim() || !this.getTrecho.trecho2.trim()) {
          return alert('ERRO: Trecho 1 ou Trecho 2 vazio.')
        }
      }

      if (!this.getviaValue) {
        return alert('ERRO: Campo "via" vazio.')
      }

      if (!this.getSentido) {
        return alert('ERRO: Campo "sentido" vazio.')
      }

      console.log("msg de alerta" + JSON.stringify(msgAlerta));
      await this.sendAlertas(msgAlerta).then(this.showModal());
    },
    showModal() {
      const myModal = new bootstrapBundleMin.Modal('#modalOk', { keyboard: true });
      myModal.show();
    },
  }
}
</script>