import axios from "axios";

const endpoint =
  process.env.VUE_APP_AMBIENTE === "PRODUCAO"
    ? process.env.VUE_APP_BACKEND_GOOGLE_PLACES
    : "http://localhost:3000";

const googleAPI = axios.create({
  baseURL: `${endpoint}`,
  headers: {
    "x-api-key": "84093d39-28b4-414f-bd1c-979a10407c8f",
    "Access-Control-Allow-Origin": "*",
  },
});

export default googleAPI;
