<template>
    <div class="modal fade modal-dialog modal-dialog-centere modal-accept-cancel" id="modalOk" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content modal-content-accept-cancel">
                <div class="modal-body modal-ok-body">
                    <span class="modal-text-body text-uppercase"> {{ text }} </span>
                    <button type="button" class="btn-enviar btn-ok" data-bs-dismiss="modal">
                        <span><img src="../../assets/img/icons/icon-check-circle.png"></span>
                        <span>Ok</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "ModalOK",
    props: {
        text: String,
    },
}
</script>