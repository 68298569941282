export default class Alerta {
  constructor(tipo, previsao, nivel, via, sentido, trecho1, trecho2) {
    this.tipo = tipo;
    this.previsao = previsao;
    this.nivel = nivel;
    this.via = via;
    this.sentido = sentido;
    this.trecho1 = trecho1 ? trecho1 : null;
    this.trecho2 = trecho2 ? trecho2 : null;
  }
}
