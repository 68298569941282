<template>
    <div class="container my-5 conteudo-card-mensagem">
        <div class="card-cabecalho mt-5">
            PREVIEW DA MENSAGEM POR WPP
        </div>
        <div class="card card-mensagem">
            <div class="card-body">
                <div class="sub-title-mensagem ">
                    <p class="text-capitalize">WhatsApp</p>
                    <p class="text-uppercase">fra_01</p>
                    <p class="text-capitalize">Titulo: Prefeitura de Mesquita – Subsecretaria de Trânsito</p>
                    <p class="text-uppercase">Alerta</p>
                </div>
                <p v-if="getInformaTrecho" class="text-card-mensagem">
                    <span v-if="tipo !== 'Via Liberada'">Interdição</span> {{ tipo }} na <span
                        v-if="tipo !== 'Via Liberada'">via</span> {{ via }} sentido ({{ sentido }}) do(a) <strong>{{
                    trecho1 }}</strong> ao
                    <strong>{{ trecho2 }}</strong> <span v-if="tipo !== 'Via Liberada'">{{ previsao
                        }}.</span> <span v-if="tipo !== 'Via Liberada'">Evite a
                        região.</span>
                </p>
                <p v-else class="text-card-mensagem">
                    <span v-if="tipo !== 'Via Liberada'">Interdição</span> {{ tipo }} na <span
                        v-if="tipo !== 'Via Liberada'">via</span> {{ via }} sentido ({{ sentido }}) <span
                        v-if="tipo !== 'Via Liberada'">{{
                    previsao
                }}.</span> <span v-if="tipo !== 'Via Liberada'"> Evite a
                        região.</span>
                </p>
            </div>

            <div class="mx-2 mt-0 py-0">
                <img :src="tipo === 'Via Liberada' ? '../../../img/alertas/VIA_LIBERADA.png' : `${severidade}`"
                    class="card-img-bottom img-card-wpp" alt="...">
            </div>
            <div>
                <p class="card-text rodape-card-wpp">💡 Você recebeu esta mensagem, pois tem este local ativo em seus
                    alertas de trânsito.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CardPreviewWPP',
    props: {
        tipo: {
            type: String,
            default: ''
        },
        previsao: {
            type: String,
            default: ''
        },
        severidade: {
            type: String,
            default: ''
        },
        via: {
            type: String,
            default: ''
        },
        sentido: {
            type: String,
            default: ''
        },
        trecho1: {
            type: String,
            default: ''
        },
        trecho2: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters(["getIsEstagio", "getInformaTrecho"])
    }
}
</script>