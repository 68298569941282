<script setup>
import googleAPI from '@/services/api-google';

import { defineProps, ref, watch, computed } from 'vue';
import { useStore } from "vuex";

const props = defineProps({
    label: String,
    opcoes: Array,
    method: String
})

const text = ref('');
const store = useStore();

store.commit(`${props.method}`, '')

function handleChangeInput(e) {
    store.commit(`${props.method}`, e.target.value);
}


const isEmpty = () => {
    if (store.state.moduloDisparaAlertas.viaValue === '') {
        text.value = ''
        store.commit(`${props.method}`, text.value);
    }
}

watch(text, async (novo, antigo) => {
    if (novo !== '') {
        const req = await googleAPI.post('/api/places', {
            "textQuery": novo
        })
        const res = await req.data.data
        store.commit('CHANGE_VALUE_VIA', res);
    }
})

const vias = computed(() => store.getters["getVia"]);

</script>
<template>
    <!-- <div>
        <label :for="props.label" class="form-label label-input">{{ props.label }}</label><br>
        <input list="browsers" id="myBrowser" name="myBrowser" type="text" class="input-text" v-model="text"
            @input="handleChangeInput" v-on:blur="isEmpty()" />
        <img src="" alt="" srcset="">
        <datalist id="browsers">
            <option v-for="via in props.opcoes" :value="via.opcao"></option>
        </datalist>
    </div> -->
    <div>
        <label for="via" class="form-label label-input">Via</label><br>
        <input list="browsers" id="myBrowser" name="myBrowser" type="text" class="input-text" v-model="text"
            @input="handleChangeInput" v-on:blur="isEmpty()" />
        <img src="" alt="" srcset="">
        <datalist id="browsers">
            <option v-for="via in vias" :value="via.opcao"></option>
        </datalist>
    </div>
</template>

<style scoped>
input {
    background: url('../../assets/img/icons/icon-lupa.png');
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: right;
    background-position-x: 394px;
    border: 1px solid #ced4da;
}
</style>