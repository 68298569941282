<script setup>
import { defineProps, ref, computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
    label: String,
    method: String,
    value: String
});

const text = ref('');
const store = useStore();

function handleChangeInput(e) {
    store.commit(`${props.method}`, e.target.value);
}

const val = computed(() => props.value ? props.value : text.value)

</script>

<template>
    <div class="mt-2">
        <label :for="props.label" class="form-label label-input">{{ props.label }}</label><br>
        <input type="text" class="input-text" v-model="val" @input="handleChangeInput"
            style="border: 1px solid #ced4da;">
    </div>
</template>