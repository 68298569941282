<script setup>
import { defineProps } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
    label: String,
    method: String
})

const store = useStore();

function handleChangeTrecho(value) {
    store.commit(`${props.method}`, value)
}

</script>

<template>
    <div class="mt-2">
        <label class="form-label label-input">{{ props.label }}: </label>
        <div class="form-check">
            <input class="form-check-input" type="radio" :name="props.label" :id="props.label"
                @click="handleChangeTrecho(true)">
            <label class="form-check-label" :for="props.label">
                SIM
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" :name="props.label" :id="props.label" checked
                @click="handleChangeTrecho(false)">
            <label class="form-check-label" :for="props.label">
                NÃO
            </label>
        </div>
    </div>
</template>
