import axios from "axios";

console.log("ambiente: ", process.env.VUE_APP_AMBIENTE);

console.log(
  "backend: ",
  process.env.VUE_APP_AMBIENTE === "PRODUCAO"
    ? process.env.VUE_APP_ZENVIA_URL_BASE
    : "http://localhost:3333"
);

const zenviaAPI = axios.create({
  baseURL:
    process.env.VUE_APP_AMBIENTE === "PRODUCAO"
      ? process.env.VUE_APP_ZENVIA_URL_BASE
      : "http://localhost:3333",
  headers: {
    "x-api-key": "84093d39-28b4-414f-bd1c-979a10407c8f",
    "Access-Control-Allow-Origin": "*",
  },
});

export default zenviaAPI;
