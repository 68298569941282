<template>
    <div class="container">
        <div class="row ">

            <div class="col"><input-select :id="'tipo'" @change="$emit('mudaTipo', opcao)" @envia-opcao="getOpcao"
                    label="Tipo" size="lg" :opcoes="getTipo" />
            </div>

            <div class="col"><input-select :id="'previsao'" @change="$emit('mudaPrevisao', opcao)"
                    @envia-opcao="getOpcao" label="Previsão de Liberação" size="lg" :opcoes="getPrevisao"
                    :disabled="disabled" />
            </div>

            <div class="col"><input-select :id="'evento'" @change="$emit('mudaSeveridade', opcao)"
                    @envia-opcao="getOpcao" label="Nível de Evento" size="lg" :opcoes="getNiveis" :caminho-i-m-g=true
                    :disabled="disabled" :is-liberada="disabled" />
            </div>

            <!-- <div class="col"><input-select :id="'via'" @change="$emit('mudaVia', opcao)" @envia-opcao="getOpcao" label="Via"
                    size="lg" :opcoes="getVias" /></div> -->

            <div class="col">
                <input-text-data-list label="via" :opcoes="getVias" method="SET_VALUE_VIA"></input-text-data-list>
            </div>

            <div class="col">
                <input-form-text label="sentido" method="SET_VALUE_SENTIDO"
                    :value="$store.state.moduloDisparaAlertas.sentido" />
            </div>

            <div class="d-flex justify-content-between mt-3" style="width: 438px;">
                <radio-select label="Ambas as vias" method="CHANGE_AMBOS_SENTIDOS" />
                <radio-select label="informar trecho" method="CHANGE_INFORMA_TRECHO" />
            </div>

            <div v-if="this.$store.state.moduloDisparaAlertas.informarTrecho" class="col">
                <input-form-text label="Trecho 1" method="SET_VALUE_TRECHO_ONE" />
                <input-form-text label="Trecho 2" method="SET_VALUE_TRECHO_TWO" />
            </div>
        </div>
        <!-- <div class="row row-cols-2">
            <div class="col"><input-datepicker label="Data inicial" /></div>
            <div class="col"><input-datepicker label="Data final" /></div>
            <div class="col"><input-timepicker label="Hora" /></div>
        </div> -->


    </div>
</template>

<script>
import InputSelect from "../Input/InputSelect.vue";
import InputDatepicker from "../Input/InputDatepicker.vue";
import InputTimepicker from "../Input/InputTimepicker.vue";
import InputFormText from "../Input/inputFormText.vue";

import { mapGetters } from "vuex";
import RadioSelect from "../Input/radioSelect.vue";
import InputTextDataList from "../Input/inputTextDataList.vue";

export default {
    components: { InputSelect, InputDatepicker, InputTimepicker, RadioSelect, InputFormText, InputTextDataList },
    name: 'CardOpcoesAlertas',
    emits: [
        'mudaTipo',
        'mudaPrevisao',
        'mudaSeveridade',
        'mudaVia',
    ],
    data() {
        return {
            opcao: '',
            disabled: false,
        }
    },
    computed: {
        ...mapGetters([
            "getTipo",
            "getPrevisao",
            "getNiveis",
            "getVias",
            "getSentido"
        ]),
    },
    watch: {
        opcao(newValue) {
            if (newValue === 'Via Liberada') {
                this.disabled = true
            } else if (newValue === 'Total' | newValue === 'Parcial') {
                this.disabled = false
            }
        }
    },
    methods: {
        getOpcao(opcao) {
            this.opcao = opcao;
        },

        setTrechoOne(value) {
            console.log(value)
        }

        // getTrecho(value) {
        //     this.trecho = value;
        // }
    }
}
</script>