import zenviaAPI from "@/services/api-zenvia";

const moduloDisparaAlertas = {
  state: {
    tipo: [{ opcao: "Total" }, { opcao: "Parcial" }, { opcao: "Via Liberada" }],
    previsao: [
      { opcao: "com previsão de liberação nas próximas horas" },
      { opcao: "sem previsão de liberação" },
    ],
    niveis_severidade: [
      // { opcao: "1", caminhoIMG: "../../../img/alertas/alerta_transito01.jpg" },
      // { opcao: "2", caminhoIMG: "../../../img/alertas/alerta_transito02.jpg" },
      // { opcao: "3", caminhoIMG: "../../../img/alertas/alerta_transito03.jpg" },
      {
        opcao: "Alagamento - Laranja",
        caminhoIMG: "../../../img/alertas/ALAGAMENTO_LARANJA.png",
      },
      {
        opcao: "Alagamento - Vermelho",
        caminhoIMG: "../../../img/alertas/ALAGAMENTO_VERMELHO.png",
      },
      {
        opcao: "Incêndio - Laranja",
        caminhoIMG: "../../../img/alertas/INCENDIO_LARANJA.png",
      },
      {
        opcao: "Incêndio - Vermelho",
        caminhoIMG: "../../../img/alertas/INCENDIO_VERMELHO.png",
      },
      {
        opcao: "Acidente de Trânsito - Laranja",
        caminhoIMG: "../../../img/alertas/ACIDENTE_LARANJA.png",
      },
      {
        opcao: "Acidente de Trânsito - Vermelho",
        caminhoIMG: "../../../img/alertas/ACIDENTE_VERMELHO.png",
      },
      {
        opcao: "Evento Cultural - Laranja",
        caminhoIMG: "../../../img/alertas/EVENTO_CULTURAL_LARANJA.png",
      },
      {
        opcao: "Evento Cultural - Vermelho",
        caminhoIMG: "../../../img/alertas/EVENTO_CULTURAL_VERMELHO.png",
      },
      {
        opcao: "Obras na Pista - Laranja",
        caminhoIMG: "../../../img/alertas/OBRA_NA_PISTA_LARANJA.png",
      },
      {
        opcao: "Obras na Pista - Vermelho",
        caminhoIMG: "../../../img/alertas/OBRA_NA_PISTA_VERMELHO.png",
      },
      {
        opcao: "Queda de Árvore - Laranja",
        caminhoIMG: "../../../img/alertas/QUEDA_DE_ÁRVORE_LARANJA.png",
      },
      {
        opcao: "Queda de Árvore - Vermelho",
        caminhoIMG: "../../../img/alertas/QUEDA_DE_ÁRVORE_VERMELHA.png",
      },
      {
        opcao: "Fio Energizado na Via - Laranja",
        caminhoIMG: "../../../img/alertas/FIO_ENERGIZADO_NA_VIA_LARANJA.jpg",
      },
      {
        opcao: "Fio Energizado na Via - Vermelho",
        caminhoIMG: "../../../img/alertas/FIO_ENERGIZADO_NA_VIA_VERMELHO.jpg",
      },
      {
        opcao: "Evento Esportivo - Laranja",
        caminhoIMG: "../../../img/alertas/EVENTO_ESPORTIVO_LARANJA.jpg",
      },
      {
        opcao: "Evento Esportivo - Vermelho",
        caminhoIMG: "../../../img/alertas/EVENTO_ESPORTIVO_VERMELHO.jpg",
      },
      {
        opcao: "Veiculo Enguiçado - Laranja",
        caminhoIMG: "../../../img/alertas/VEICULO_ENGUICADO_LARANJA.jpg",
      },
      {
        opcao: "Veiculo Enguiçado - Vermelho",
        caminhoIMG: "../../../img/alertas/VEICULO_ENGUICADO_VERMELHO.jpg",
      },
      {
        opcao: "Via Liberada",
        caminhoIMG: "../../../img/alertas/VIA_LIBERADA.png",
      },
    ],
    vias: [
      { opcao: "Av. União (Sentido Nilópolis)" },
      { opcao: "Av. União (Sentido Centro)" },
      { opcao: "Av. São Paulo (Sentido Centro)" },
      { opcao: "Av. São Paulo (Sentido N. Iguaçu)" },
      { opcao: "Av. Getúlio de Moura (Sentido Nilópolis)" },
      { opcao: "Av. Getúlio de Moura (Sentido N. Iguaçu)" },
      { opcao: "Av. Pres. Costa e Silva (Sentido Nilópolis)" },
      { opcao: "Av. Pres. Costa e Silva (Sentido N. Iguaçu)" },
      { opcao: "Av. Baronesa de Mesquita (Sentido Nilópolis)" },
      { opcao: "Av. Baronesa de Mesquita (Sentido N. Iguaçu)" },
      { opcao: "R. Cosmorama (Sentido R. Célio Azevedo)" },
      { opcao: "R. Cosmorama (Sentido Av. Baronesa de Mesquita)" },
      { opcao: "Av. Gov. Celso Peçanha (Sentido Av. Baronesa de Mesquita)" },
      { opcao: "Av. Gov. Celso Peçanha (Sentido Av. Coelho da Rocha)" },
      { opcao: "Av. Coelho da Rocha (Sentido Via Dutra)" },
      { opcao: "Av. Coelho da Rocha (Sentido Via Light)" },
      { opcao: "Viaduto Dicró (Sentido Nilópolis)" },
      { opcao: "Viaduto Dicró (Sentido N. Iguaçu)" },
      { opcao: "Est. Feliciano Sodré (Sentido Centro)" },
      { opcao: "Est. Feliciano Sodré (Sentido N. Iguaçu)" },
      // add
      { opcao: "R. PARANÁ (SENTIDO CENTRO)" },
      { opcao: "R. PARANÁ (SENTIDO JUSCELINO)" },
      { opcao: "R. PAPA JOÃO XXIII" },
      { opcao: "R. PREFEITO JOSÉ MONTES PAIXÃO (SENTIDO EDSON PASSOS)" },
      { opcao: "R. PREFEITO JOSÉ MONTES PAIXÃO (SENTIDO CENTRO)" },
      { opcao: "R. HEITOR DA COSTA VAL" },
      { opcao: "R. ARTHUR DE OLIVEIRA VECCHI" },
      // add
      { opcao: "Av. Marechal Castelo Branco" },
      { opcao: "Rua João Pereira Goulart" },
      { opcao: "Rua Hercília" },
      {
        opcao:
          "Rua Tenente Aldir Soares Adriano (sentido Av. Getúlio de Moura)",
      },
      { opcao: "Rua Tenente Aldir Soares Adriano (sentido 20⁰ BPM)" },
      { opcao: "Rua Ambrósio (sentido estação)" },
      { opcao: "Rua Ambrósio (sentido Santo Elias)" },
      { opcao: "Rua: Virtude (sentido estação)" },
      { opcao: "Rua: Virtude (sentido Santo Elias)" },
      { opcao: "Rua Verdade (sentido estação)" },
      { opcao: "Rua Verdade (sentido Santo Elias)" },
      { opcao: "Rua Marcial (sentido estação)" },
      { opcao: "Rua Marcial (sentido Santo Elias)" },
      { opcao: "Av. Laura Gonçalves Machado (sentido Estação)" },
      { opcao: "Av. Laura Gonçalves Machado (sentido Rua Célio Azevedo)" },
      { opcao: "Rua Capitão Teles (sentido av. Getúlio de Moura)" },
      { opcao: "Rua Capitão Teles (sentido Rua Júlia Viana Steinbuch)" },
      { opcao: "Rua Mister watkins" },
      { opcao: "Rua João Quintino dos Santos" },
    ],
    // INICIO TESTE
    via: [],
    // FIM TESTE
    sentido: "",
    // variavel que recebera o valor escolhido de via
    viaValue: "",
    oldViaValue: "",
    informarTrecho: false,
    ambosSentidos: false,
    trecho: {
      trecho1: "",
      trecho2: "",
    },
    mensagem: "",
  },
  getters: {
    getTipo: (state) => state.tipo,
    getPrevisao: (state) => state.previsao,
    getNiveis: (state) => state.niveis_severidade,
    getVias: (state) => state.vias,
    getMensagem: (state) => state.mensagem,
    getInformaTrecho: (state) => state.informarTrecho,
    getAmbosSentidos: (state) => state.ambosSentidos,
    getTrecho: (state) => state.trecho,
    getviaValue: (state) => state.viaValue,
    getOldViaValue: (state) => state.oldViaValue,
    // TESTE
    getVia: (state) => state.via,
    getSentido: (state) => state.sentido,
  },
  actions: {
    sendAlertas({ commit }, msgAlerta) {
      commit("sendAlerta", msgAlerta);
    },
  },
  mutations: {
    sendAlerta: (state, msgAlerta) => {
      console.log("Enviando o Alerta", msgAlerta);
      zenviaAPI
        .post("/api/send/alerta/transito", msgAlerta)
        .then((resp) => {
          console.log("Alerta enviado com sucesso", resp);
          return (state.mensagem = "Alerta enviado com sucesso");
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              (state.mensagem =
                "A requisição foi feita e o servidor respondeu com um código de status que sai do alcance de 2xx")
            );
            console.error("Payload: ", error.response.data);
            console.error("Status: ", error.response.status);
            console.error("Headers: ", error.response.headers);
            return state.mensagem;
          } else if (error.request) {
            // `error.request` é uma instância do XMLHttpRequest no navegador e uma instância de
            // http.ClientRequest no node.js
            console.error(
              (state.mensagem =
                "A requisição foi feita mas nenhuma resposta foi recebida :"),
              error.request
            );
            return state.mensagem;
          } else {
            // Alguma coisa acontenceu ao configurar a requisição que acionou este erro.
            console.error("Error", error.message);
            state.mensagem = "Erro durante o disparo de alerta!";
            return state.mensagem;
          }
        });
    },
    CHANGE_INFORMA_TRECHO: (state, value) => {
      state.informarTrecho = value;
      if (value === false) {
        state.trecho.trecho1 = "";
        state.trecho.trecho2 = "";
      }
    },
    CHANGE_AMBOS_SENTIDOS: (state, value) => {
      state.ambosSentidos = value;
      if (state.ambosSentidos) {
        state.sentido = "ambos";
        return;
      }
      state.sentido = "";
      return;

      // let oldValue = state.oldViaValue;
      // const caracter = state.viaValue.indexOf("(");
      // if (value && caracter !== -1) {
      //   const newValue = state.viaValue.substring(0, caracter - 1);
      //   state.viaValue = `${newValue} (Ambos os Sentidos)`;
      //   return;
      // }
      // state.viaValue = oldValue;
      // return;
    },
    SET_VALUE_TRECHO_ONE: (state, value) => {
      state.trecho.trecho1 = value;
    },
    SET_VALUE_TRECHO_TWO: (state, value) => {
      state.trecho.trecho2 = value;
    },
    SET_VALUE_VIA: (state, value) => {
      state.viaValue = value;
      state.oldViaValue = value;
    },
    CHANGE_VALUE_VIA: (state, value) => {
      if (value) {
        state.via = [];
        value.map((via) => {
          state.via.push({ opcao: via.displayName.text });
        });
      }
    },
    SET_VALUE_SENTIDO: (state, value) => {
      state.sentido = value;
    },
  },
};

export default moduloDisparaAlertas;
