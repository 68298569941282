<template>
    <div class="container conteudo-card-mensagem">
        <div class="card-cabecalho">
            PREVIEW DA MENSAGEM POR SMS
        </div>
        <div class="card card-mensagem">
            <div class="card-body">
                <div class="text-card-mensagem">
                    <p>Prefeitura de Mesquita: <span v-if="tipo !== 'Via Liberada'">Interdição</span> {{ tipo }} na
                        via {{ via }} sentido ({{ sentido }}) <span v-if="tipo !== 'Via Liberada'">{{ previsao
                            }}.</span>
                        <span v-if="tipo !== 'Via Liberada'"> Evite a região.</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'CardPreviewSMS',
    props: {
        tipo: {
            type: String,
            default: ''
        },
        previsao: {
            type: String,
            default: ''
        },
        via: {
            type: String,
            default: ''
        },
        sentido: {
            type: String,
            default: ''
        },
        trecho1: {
            type: String,
            default: ''
        },
        trecho2: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters(['getInformaTrecho'])
    },

}
</script>